import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";

import hero from "../imgs/hero.jpeg";

import ReactStars from "react-stars";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  data,
  course_nt,
  chem_courses,
  bio_courses,
  comp_courses,
  reviews,
  slider,
}) => {
  
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    // auto scroll
    autoplay: true,
    autoplaySpeed: 5000,
  };

  var img_slider_settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    // auto scroll
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <main>
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="text-wp">
                <h2>{data.title}</h2>
                <h1>{data.heading}</h1>

                <h5>{data.subheading}</h5>

                <p>{data.description}</p>

                <Slider {...settings}>
                  {reviews.map(({ node: review }, index) => (
                    <div className="testimonial-wrap">
                      <ReactStars
                        count={5}
                        edit={false}
                        value={
                          review.frontmatter.stars
                            ? parseInt(review.frontmatter.stars)
                            : 0
                        }
                        size={"38px"}
                      ></ReactStars>
                      <p>{review.frontmatter.review}</p>

                      <h6 style={{ marginTop: "16px" }}>
                        {review.frontmatter.name}
                      </h6>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="img-wrap">
                {/* <GatsbyImage 
                  image={getImage(data.image.childImageSharp)}
                  alt=""
                /> */}
                <img src={hero} alt="hero" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="img-section">
        <div className="container">
          <Slider {...img_slider_settings}>
            {slider.map(({ node: review }, index) => (
              <div className="slider-img-wrap">
                <img
                  src={review.frontmatter.slider_image.childImageSharp.gatsbyImageData.images.fallback.src}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section className="hero-dark bg-blck section-padding">
        <div className="container position-re">
          <div className="row">
            <div className="col-lg-12">
              <div className="cont">
                <h6
                  className="md-title mb-15 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  // About Apex Learning Institute
                </h6>
                <h2 className="js-splittext-lines">
                  {data.index_about.heading}
                </h2>
              </div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-lg-5">
              <div className="img mr-30">
                <StaticImage src="../imgs/home/about-us.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="text">
                <p style={{ fontSize: "20px", lineHeight: "1.6" }}>
                  {data.index_about.description}
                </p>
              </div>
              <div className="mt-40">
                <Link
                  to="/about"
                  className="butn-circle butn-light d-flex align-items-center justify-content-center text-center"
                >
                  <div>
                    <span className="text fw-700 mb-15">About us</span>
                    <br />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                        stroke="#fff"
                        strokeWidth="2"
                      ></path>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services section-padding">
        <div className="container">
          <div className="sec-head mb-80">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="text-center">
                  <h6 className="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                    // What we offer?
                  </h6>
                  <h3 className="fw-700 js-splittext-lines">
                    {data.whatWeOffer.heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row md-marg">
            {data.whatWeOffer.features.map((service, index) => (
              <div className="col-lg-4 mb-4" key={index}>
                <div
                  className="item text-center md-mb50 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <h5 className="fw-700">{service.title}</h5>
                  <div className="text mt-15">
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="serv-dark bg-blck section-padding"
        id="courses"
        name="courses"
      >
        <div className="container">
          <div className="sec-head mb-80">
            <div className="row">
              <div className="col-lg-8">
                <h6 className="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                  // Best Of our courses
                </h6>
                <h2 className="fw-700 js-splittext-lines">
                  Empowering Futures: Comprehensive Chemistry and Biology
                  Courses
                </h2>
              </div>
              <div className="col-lg-4 d-flex align-items-center justify-content-end">
                <div className="head-cont">
                  <div className="icon-img-60 icon mb-80">
                    <StaticImage
                      src="../imgs/vector-img/plus-light.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="divider" style={{ marginBottom: "48px" }}>
            <h3>
              <span>9th-10th Class</span>
            </h3>
          </div>
          <div className="row">
            {course_nt.map(({ node: course }, index) => (
              <div className="col-lg-6">
                <div className="item d-flex pb-40 mb-40 bord-thin-bottom">
                  <span className="num mr-30">
                    {index < 9 ? `0${index + 1}` : index + 1}.
                  </span>
                  <div className="cont ml-80">
                    <Link>
                      <h5 className="fw-700 mb-15">
                        {course.frontmatter.name}
                      </h5>
                    </Link>
                    <p>{course.frontmatter.subheading}</p>
                    <Link
                      to={course.fields.slug}
                      className="butn butn-md bg-transparent mt-4"
                    >
                      <span className="text">Course Details</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="divider"
            style={{ marginBottom: "48px", marginTop: "48px" }}
          >
            <h3>
              <span>Chemistry</span>
            </h3>
          </div>
          <div className="row">
            {chem_courses.map(({ node: course }, index) => (
              <div className="col-lg-6">
                <div className="item d-flex pb-40 mb-40 bord-thin-bottom">
                  <span className="num mr-30">
                    {index < 9 ? `0${index + 1}` : index + 1}.
                  </span>
                  <div className="cont ml-80">
                    <Link>
                      <h5 className="fw-700 mb-15">
                        {course.frontmatter.name}
                      </h5>
                    </Link>
                    <p>{course.frontmatter.subheading}</p>
                    <Link
                      to={course.fields.slug}
                      className="butn butn-md bg-transparent mt-4"
                    >
                      <span className="text">Course Details</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="divider"
            style={{ marginBottom: "48px", marginTop: "48px" }}
          >
            <h3>
              <span>Biology</span>
            </h3>
          </div>
          <div className="row">
            {bio_courses.map(({ node: course }, index) => (
              <div className="col-lg-6">
                <div className="item d-flex pb-40 mb-40 bord-thin-bottom">
                  <span className="num mr-30">
                    {index < 9 ? `0${index + 1}` : index + 1}.
                  </span>
                  <div className="cont ml-80">
                    <Link>
                      <h5 className="fw-700 mb-15">
                        {course.frontmatter.name}
                      </h5>
                    </Link>
                    <p>{course.frontmatter.subheading}</p>
                    <Link
                      to={course.fields.slug}
                      className="butn butn-md bg-transparent mt-4"
                    >
                      <span className="text">Course Details</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="divider"
            style={{ marginBottom: "48px", marginTop: "48px" }}
          >
            <h3>
              <span>Competitive Exams</span>
            </h3>
          </div>
          <div className="row">
            {comp_courses.map(({ node: course }, index) => (
              <div className="col-lg-6">
                <div className="item d-flex pb-40 mb-40 bord-thin-bottom">
                  <span className="num mr-30">
                    {index < 9 ? `0${index + 1}` : index + 1}.
                  </span>
                  <div className="cont ml-80">
                    <Link>
                      <h5 className="fw-700 mb-15">
                        {course.frontmatter.name}
                      </h5>
                    </Link>
                    <p>{course.frontmatter.subheading}</p>
                    <Link
                      to={course.fields.slug}
                      className="butn butn-md bg-transparent mt-4"
                    >
                      <span className="text">Course Details</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="team section-padding" id="alumni" name="alumni">
        <div className="container">
          <div className="sec-head mb-80">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="text-center">
                  <h6 className="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                    // Our Scholars
                  </h6>
                  <h3 className="fw-700 js-splittext-lines">
                    {data.ourScholars.heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row alumni-row">
            {data.ourScholars.scholars.map((scholar, index) => (
              <div className="col-lg-3 col-md-6" key={index}>
                <div className="item md-mb50 wow fadeIn" data-wow-delay="0.4s">
                  <div className="img">
                    <GatsbyImage
                      image={getImage(scholar.image.childImageSharp)}
                      alt=""
                    />
                  </div>
                  <div className="info text-center mt-15">
                    <h5 className="fw-700 mb-5">{scholar.name}</h5>
                    <span className="sub-font p-color">
                      {scholar.description}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="faqs">
        <div className="main-marq sub-font mb-80">
          <div className="slide-har st1">
            <div className="box non-strok">
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
            </div>
            <div className="box non-strok">
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
              <div className="item">
                <h4 className="d-flex align-items-center">
                  <span>Apex Learning</span>{" "}
                  <span className="icon">
                    <StaticImage src="../imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="numbers section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb30">
                <div>
                  <h3>{data.bigNumbers.no_of_exp}</h3>
                  <div className="flx">
                    <span className="icon">
                      <i className="ti-clipboard"></i>
                    </span>
                    <span className="sm-title">Years Experince</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item md-mb30">
                <div>
                  <h3>{data.bigNumbers.std_enrl}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-star"></i>
                    </span>
                    <span className="sm-title">Students Enrolled</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item sm-mb30">
                <div>
                  <h3>{data.bigNumbers.reviews}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-medall"></i>
                    </span>
                    <span className="sm-title">Reviews</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <div>
                  <h3>{data.bigNumbers.satisfaction}</h3>
                  <div>
                    <span className="icon">
                      <i className="ti-direction-alt"></i>
                    </span>
                    <span className="sm-title">Satisfaction</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faqs" style={{ paddingBottom: "8rem" }}>
        <div className="position-re">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10">
                <div className="sec-head mb-40">
                  <h6 className="mb-15 wow fadeInUp" data-wow-delay="0.4s">
                    // Any Questions in you Mind?
                  </h6>
                  <h3 className="fw-700 mb-15 js-splittext-lines">
                    {data.faqs.heading}
                  </h3>
                </div>
                <Accordion>
                  {data.faqs.questions.map((faq, index) => (
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {faq.question}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{faq.answer}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
          <div className="imgs">
            <div className="img1 fit-img wow fadeIn" data-wow-delay="0.4s">
              <StaticImage src="../imgs/home/3.jpg" alt="" />
            </div>
            <div className="img2 fit-img wow fadeIn" data-wow-delay="0.6s">
              <StaticImage src="../imgs/home/2.jpg" alt="" />
            </div>
            <div className="img3 fit-img wow fadeIn" data-wow-delay="0.8s">
              <StaticImage src="../imgs/home/5.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

IndexPageTemplate.prototype = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.home_data;
  const { edges: courses } = data.course_nt;
  const { edges: chem_courses } = data.course_chem;
  const { edges: bio_courses } = data.course_bio;
  const { edges: comp_courses } = data.course_comp;
  const { edges: reviews } = data.reviews;
  const { edges: slider } = data.slider;

  return (
    <Layout>
      <IndexPageTemplate
        data={frontmatter}
        course_nt={courses}
        chem_courses={chem_courses}
        bio_courses={bio_courses}
        comp_courses={comp_courses}
        reviews={reviews}
        slider={slider}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    home_data: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        description
        reviews {
          quote
          author
        }

        index_about {
          heading
          description
        }

        whatWeOffer {
          heading
          features {
            title
            description
          }
        }

        ourScholars {
          heading
          scholars {
            name
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }

        bigNumbers {
          no_of_exp
          std_enrl
          reviews
          satisfaction
        }

        faqs {
          heading
          questions {
            question
            answer
          }
        }
      }
    }
    course_nt: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "course-details" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            name
            desc
            subheading
          }
        }
      }
    }
    course_chem: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "chemistry-course-details" } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            name
            desc
            subheading
          }
        }
      }
    }
    course_bio: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "biology-course-details" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            name
            desc
            subheading
          }
        }
      }
    }
    course_comp: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "competitive-course-details" } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            name
            desc
            subheading
          }
        }
      }
    }
    reviews: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "review" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            review
            stars
          }
        }
      }
    }
    slider: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "slider" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slider_image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
